<!--
 * @Author: Libra
 * @Date: 2022-03-29 17:53:38
 * @LastEditTime: 2023-02-13 18:31:24
 * @LastEditors: Libra
 * @Description: 人脸识别
 * @FilePath: /stone-exam-ui/src/views/confirm/face/index.vue
-->
<template>
  <div class="my-container">
    <el-dialog
      title="手机扫码人脸识别"
      :visible.sync="showPhoneFaceDetect"
      :before-close="beforePhoneFaceDetectClose"
      width="400px"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
    >
      <div class="content-contaienr">
        <div
          v-loading="qrLoading"
          element-loading-text="二维码生成中"
          class="qr-container"
          style="width: 200px;height: 200px;"
        >
          <vue-qr
            style="width: 200px;height: 200px;"
            :size="800"
            :text="qrContent"
          ></vue-qr>
          <div class="refresh-qr" v-if="isFailure">
            <span class="failure">二维码已失效</span>
            <el-button type="danger" @click="refresh">请点击刷新</el-button>
          </div>
        </div>
        <span class="upload-label">手机微信扫码</span>
        <el-button
          type="danger"
          :loading="hasResult"
          @click="getFaceIdResultThreeSec"
          >获取验证结果</el-button
        >
      </div>
    </el-dialog>
    <div class="modal" v-if="dialogVisible">
      <div class="iframe-container">
        <div @click="closeDialog()" class="svg-container">
          <img
            width="20px"
            height="20px"
            style="cursor: pointer"
            src="../../../svgIcon/svg/close.svg"
            alt=""
          />
        </div>
        <iframe
          allow="geolocation;microphone;camera"
          class="iframe"
          width="100%"
          height="100%"
          :src="`https://api.faceid.com/faceid/liveness/v2/do?token=${token}`"
          scrolling="no"
        >
        </iframe>
      </div>
    </div>
    <layout>
      <div slot="content" class="section camera">
        <div class="title">身份验证</div>
        <div class="tips">* 人脸识别系统将验证考生身份，确保考试公平、公正</div>
        <div class="recognition-and-result">
          <div class="recognition">
            <img
              v-if="isPC && !avatar"
              src="../../../svgIcon/svg/identity.svg"
              alt=""
            />
            <img
              :src="`${file_host}${avatar}`"
              v-else-if="isPC && avatar"
              alt=""
            />
          </div>
          <div class="result-container">
            <div class="result" v-if="isPC">
              身份验证：
              <span
                :style="
                  verifyResult === '成功'
                    ? 'color: #43a6ff'
                    : verifyResult === '不通过'
                    ? 'color: #cb2a1d;'
                    : 'color: #1e1e1e'
                "
                >{{ verifyResult }}</span
              >
            </div>
            <div class="reason" v-if="isPC && verifyResult === '不通过'">
              原因： <span style="color: #cb2a1d">{{ verifyReason }}</span>
            </div>
            <div class="result" v-if="!isPC">
              手机微信验证
            </div>
            <span
              style="margin-bottom: 5px;"
              class="rec-tip"
              v-if="isPC && verifyResult !== '成功'"
            >
              使用手机扫码，进行身份验证
            </span>
            <span class="rec-tip" v-if="isPC && verifyResult === '成功'">
              考试全程请保持摄像头开启状态！
            </span>
            <span class="rec-tip" v-else>
              Tips：人脸请离手机近一些，头像务必在圆圈画面以内
            </span>
            <span
              class="remainTime"
              v-if="remainTime !== 3 && verifyResult !== '成功'"
              >验证剩余：<span
                style="color: #f35a5a; font-size: 22px; font-weight: bold;"
                >{{ remainTime }}</span
              >
              次</span
            >
          </div>
        </div>
        <canvas id="camera" width="500" v-show="!isVideo" height="360"></canvas>
        <div class="operate">
          <span class="prev" @click="prev">上一步</span>
          <el-button class="snap" @click="verify" v-if="isPC">
            {{
              verifyResult !== "成功"
                ? remainTime !== 3
                  ? remainTime === 0
                    ? "去拍照"
                    : "重新验证"
                  : "开始验证"
                : "已验证 下一步"
            }}
          </el-button>
          <el-button class="snap" @click="isPC = true" v-else>
            返回
          </el-button>
          <span
            class="next"
            v-if="isPC && verifyResult !== '成功' && remainTime === 0"
          >
            <span
              style="color: #c1c1c1"
              v-if="isVideo && $store.state.examInfo.cameraMonitorType !== 1"
              @click="next"
              >跳过</span
            >
          </span>
        </div>
      </div>
    </layout>
  </div>
</template>

<script>
import Layout from '@/views/confirm/layout'
import Api from '@/api/api'
import { file_host } from '@/api/config'
import VueQr from 'vue-qr'

export default {
  name: 'ConfirmCamera',
  components: {
    Layout,
    VueQr
  },
  data() {
    return {
      context: null,
      video: null,
      isVideo: true,
      mediaStreamTrack: null,
      isDenied: false,
      allowCameraVisible: false,
      token: null,
      dialogVisible: false,
      verifyResult: '未验证',
      verifyReason: '',
      isPC: true,
      remainTime: 3,
      lastReaminTime: 0,
      faceIdBizId: '',
      avatar: '',
      timer: null,
      file_host: file_host,
      qrTimer: null,
      isFailure: false,
      qrContent: '',
      qrLoading: false,
      showPhoneFaceDetect: false,
      hasResult: false
    }
  },
  mounted() {
    this.getFaceIdResult()
  },
  methods: {
    verify() {
      if (this.verifyResult === '成功') {
        this.next()
        return
      }
      if (this.remainTime === 0) {
        this.$router.push({ path: '/camera', query: { isFace: true }})
        return
      }
      this.faceRecognitionPhone()
    },
    getFaceIdResultThreeSec() {
      this.hasResult = true
      const timer = setInterval(() => {
        this.hasResult = false
        this.getFaceIdResult()
        clearInterval(timer)
      }, 3000)
    },
    // 人脸识别PC端验证
    faceRecognitionPC() {
      // 先获取Token
      Api.getFaceIdToken()
        .then((res) => {
          this.dialogVisible = true
          this.token = res.data.token
          clearInterval(this.timer)
          this.timer = setInterval(() => {
            this.getFaceIdResult()
          }, 2000)
          this.$once('hook:beforeDestroy', () => {
            clearInterval(this.timer)
          })
        })
        .catch((err) => {
          if (err === '人脸检测次数用尽') {
            // 三次用尽，去拍照页面
            this.$router.push('/camera')
            return
          }
        })
    },
    // 人脸识别手机微信端验证
    faceRecognitionPhone() {
      this.showPhoneFaceDetect = true
      this.getMobileToken()
      this.refreshQR()
      // this.isPC = false
      // this.$message.error('手机验证暂未开通，请先使用电脑验证')
    },
    closeDialog() {
      this.dialogVisible = false
    },
    // 获取faceId结果
    getFaceIdResult() {
      this.showPhoneFaceDetect = false
      Api.getFaceIdResult().then((res) => {
        const resData = res.data
        // 做过考试过程中的人脸识别了，就不允许做前面的人脸对比了
        if (resData.faceDifferenceResult !== 0) {
          this.$router.push('/camera')
          return
        }
        this.remainTime = resData.faceRecognizeRemainingCount
        if (
          this.remainTime === this.lastReaminTime &&
          this.lastReaminTime !== 0
        ) {
          return
        }
        this.lastReaminTime = this.remainTime
        if (resData.faceRecognizeResult !== 0) {
          clearInterval(this.timer)
        }
        if (resData.faceRecognizeResult === 0) {
          this.verifyResult = '未验证'
        } else if (resData.faceRecognizeResult === 1) {
          this.verifyResult = '成功'
          this.avatar = resData.avatar
        } else if (resData.faceRecognizeResult === 2) {
          this.avatar = resData.avatar
          this.verifyResult = '不通过'
          this.verifyReason = resData.faceRecognizeError
        }
      })
    },
    // 上一步
    prev() {
      this.$router.push('/basic')
    },
    // 下一步
    next() {
      const { mobileMonitorType, mobileVideoType } = this.$store.state.examInfo
      const hasMobile = mobileMonitorType === 0 && mobileVideoType === 0
      hasMobile
        ? this.$router.push('/notice')
        : this.$router.push('/mobile')
    },
    beforePhoneFaceDetectClose() {
      clearTimeout(this.qrTimer)
      this.isFailure = false
      this.showPhoneFaceDetect = false
    },
    refreshQR() {
      this.qrTimer = setTimeout(() => {
        this.isFailure = true
        clearTimeout(this.qrTimer)
      }, 60000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.qrTimer)
      })
    },
    async refresh() {
      await this.getMobileToken()
      this.isFailure = false
      this.refreshQR()
    },
    // 手机扫码上传
    async getMobileToken() {
      this.qrLoading = true
      const res = await Api.getMobileToken()
      this.qrLoading = false
      const env = process.env.NODE_ENV
      // const env = 'beta'
      if (res.code === 0) {
        this.qrContent = `https://exam.stac.fun/faceReconize_2?token=${res.data}&env=${env}&is_interview=0`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/theme/variables.scss";
@import './index.scss';
</style>
