var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "手机扫码人脸识别",
            visible: _vm.showPhoneFaceDetect,
            "before-close": _vm.beforePhoneFaceDetectClose,
            width: "400px",
            "show-close": false,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPhoneFaceDetect = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content-contaienr" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.qrLoading,
                      expression: "qrLoading",
                    },
                  ],
                  staticClass: "qr-container",
                  staticStyle: { width: "200px", height: "200px" },
                  attrs: { "element-loading-text": "二维码生成中" },
                },
                [
                  _c("vue-qr", {
                    staticStyle: { width: "200px", height: "200px" },
                    attrs: { size: 800, text: _vm.qrContent },
                  }),
                  _vm.isFailure
                    ? _c(
                        "div",
                        { staticClass: "refresh-qr" },
                        [
                          _c("span", { staticClass: "failure" }, [
                            _vm._v("二维码已失效"),
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "danger" },
                              on: { click: _vm.refresh },
                            },
                            [_vm._v("请点击刷新")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("span", { staticClass: "upload-label" }, [
                _vm._v("手机微信扫码"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", loading: _vm.hasResult },
                  on: { click: _vm.getFaceIdResultThreeSec },
                },
                [_vm._v("获取验证结果")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.dialogVisible
        ? _c("div", { staticClass: "modal" }, [
            _c("div", { staticClass: "iframe-container" }, [
              _c(
                "div",
                {
                  staticClass: "svg-container",
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      width: "20px",
                      height: "20px",
                      src: require("../../../svgIcon/svg/close.svg"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c("iframe", {
                staticClass: "iframe",
                attrs: {
                  allow: "geolocation;microphone;camera",
                  width: "100%",
                  height: "100%",
                  src: `https://api.faceid.com/faceid/liveness/v2/do?token=${_vm.token}`,
                  scrolling: "no",
                },
              }),
            ]),
          ])
        : _vm._e(),
      _c("layout", [
        _c(
          "div",
          {
            staticClass: "section camera",
            attrs: { slot: "content" },
            slot: "content",
          },
          [
            _c("div", { staticClass: "title" }, [_vm._v("身份验证")]),
            _c("div", { staticClass: "tips" }, [
              _vm._v("* 人脸识别系统将验证考生身份，确保考试公平、公正"),
            ]),
            _c("div", { staticClass: "recognition-and-result" }, [
              _c("div", { staticClass: "recognition" }, [
                _vm.isPC && !_vm.avatar
                  ? _c("img", {
                      attrs: {
                        src: require("../../../svgIcon/svg/identity.svg"),
                        alt: "",
                      },
                    })
                  : _vm.isPC && _vm.avatar
                  ? _c("img", {
                      attrs: { src: `${_vm.file_host}${_vm.avatar}`, alt: "" },
                    })
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "result-container" }, [
                _vm.isPC
                  ? _c("div", { staticClass: "result" }, [
                      _vm._v(" 身份验证： "),
                      _c(
                        "span",
                        {
                          style:
                            _vm.verifyResult === "成功"
                              ? "color: #43a6ff"
                              : _vm.verifyResult === "不通过"
                              ? "color: #cb2a1d;"
                              : "color: #1e1e1e",
                        },
                        [_vm._v(_vm._s(_vm.verifyResult))]
                      ),
                    ])
                  : _vm._e(),
                _vm.isPC && _vm.verifyResult === "不通过"
                  ? _c("div", { staticClass: "reason" }, [
                      _vm._v(" 原因： "),
                      _c("span", { staticStyle: { color: "#cb2a1d" } }, [
                        _vm._v(_vm._s(_vm.verifyReason)),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.isPC
                  ? _c("div", { staticClass: "result" }, [
                      _vm._v(" 手机微信验证 "),
                    ])
                  : _vm._e(),
                _vm.isPC && _vm.verifyResult !== "成功"
                  ? _c(
                      "span",
                      {
                        staticClass: "rec-tip",
                        staticStyle: { "margin-bottom": "5px" },
                      },
                      [_vm._v(" 使用手机扫码，进行身份验证 ")]
                    )
                  : _vm._e(),
                _vm.isPC && _vm.verifyResult === "成功"
                  ? _c("span", { staticClass: "rec-tip" }, [
                      _vm._v(" 考试全程请保持摄像头开启状态！ "),
                    ])
                  : _c("span", { staticClass: "rec-tip" }, [
                      _vm._v(
                        " Tips：人脸请离手机近一些，头像务必在圆圈画面以内 "
                      ),
                    ]),
                _vm.remainTime !== 3 && _vm.verifyResult !== "成功"
                  ? _c("span", { staticClass: "remainTime" }, [
                      _vm._v("验证剩余："),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#f35a5a",
                            "font-size": "22px",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v(_vm._s(_vm.remainTime))]
                      ),
                      _vm._v(" 次"),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("canvas", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isVideo,
                  expression: "!isVideo",
                },
              ],
              attrs: { id: "camera", width: "500", height: "360" },
            }),
            _c(
              "div",
              { staticClass: "operate" },
              [
                _c("span", { staticClass: "prev", on: { click: _vm.prev } }, [
                  _vm._v("上一步"),
                ]),
                _vm.isPC
                  ? _c(
                      "el-button",
                      { staticClass: "snap", on: { click: _vm.verify } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.verifyResult !== "成功"
                                ? _vm.remainTime !== 3
                                  ? _vm.remainTime === 0
                                    ? "去拍照"
                                    : "重新验证"
                                  : "开始验证"
                                : "已验证 下一步"
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _c(
                      "el-button",
                      {
                        staticClass: "snap",
                        on: {
                          click: function ($event) {
                            _vm.isPC = true
                          },
                        },
                      },
                      [_vm._v(" 返回 ")]
                    ),
                _vm.isPC && _vm.verifyResult !== "成功" && _vm.remainTime === 0
                  ? _c("span", { staticClass: "next" }, [
                      _vm.isVideo &&
                      _vm.$store.state.examInfo.cameraMonitorType !== 1
                        ? _c(
                            "span",
                            {
                              staticStyle: { color: "#c1c1c1" },
                              on: { click: _vm.next },
                            },
                            [_vm._v("跳过")]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }